import cookieCutter from 'cookie-cutter';
import Link from 'next/link';
import { useContext, useEffect, useState } from 'react';
import { SITE_CONFIG } from '../../config/config';
import UserContext from '../auth/context';

const NavTitle = ({ title = null }) => {
  if (title) {
    return (
      <div className="font-bold text-caarlblue text-sm sm:text-lg leading-none">
        {title}
      </div>
    );
  }
  return (
    <img
      className="h-10 object-contain mx-auto"
      src={SITE_CONFIG.headLogo}
      // src="/images/caarl_logo.svg"
      alt={SITE_CONFIG.name}
    />
  );
};

const Nav = (props) => {
  const { logout } = useContext(UserContext);
  const [externalMenu, setExternalMenu] = useState([]);

  useEffect(() => {
    const externalCookie = cookieCutter.get('caarl-menu');
    if (externalCookie) {
      setExternalMenu(JSON.parse(externalCookie));
    }
  }, []);

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
  };

  return (
    <nav className="flex justify-between items-center bg-white h-16 relative">
      <div className="text-left flex-shrink-0 flex-grow-0 w-20">
        {props.onBack && (
          <button
            type="button"
            onClick={props.onBack}
            className="whitespace-no-wrap font-normal text-caarlblue text-2xl relative inline-block align-middle p-5"
          >
            <img
              src="/images/icons/chevron_left.svg"
              alt="go home"
              className="w-8"
            />
          </button>
        )}
      </div>
      <div className="text-center flex-grow flex-shrink">
        <Link legacyBehavior href="/" passHref>
          <a href="#replace" className="">
            <NavTitle title={props.title} />
          </a>
        </Link>
      </div>
      <div className="text-right  flex-shrink-0 flex-grow-0 w-20">
        <button type="button" className="menu-toggle text-caarlblue p-6 h-16">
          <img
            src="/images/icons/hamburger.svg"
            alt="menu opener"
            className="w-8 transform scale-75"
          />
        </button>
        <div className="menu-content bg-white shadow w-64 absolute right-0 font-normal z-10">
          <ul className="list-reset">
            {externalMenu.map((mElement) => (
              <li key={mElement.link}>
                <a
                  type="button"
                  href={mElement.link}
                  className="block p-4 text-caarlblue border-white hover:pl-6 hover:border-caarlblue border-r-4 w-full text-right"
                >
                  {mElement.label}
                </a>
              </li>
            ))}
            {/* <li>
              <button
                type="button"
                className="block p-4 text-caarlblue border-white hover:pl-6 hover:border-caarlblue border-r-4 w-full text-right"
              >
                Mon profil
              </button>
            </li> */}
            <li>
              <button
                type="button"
                onClick={handleLogout}
                className="block p-4 text-caarlblue border-white hover:pl-6 hover:border-caarlblue border-r-4 w-full text-right"
              >
                Déconnexion
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
