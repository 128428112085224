const ChoiceButton = (props) => (
  <button
    onClick={props.onClick}
    type="button"
    className={`bg-caarlgreen text-darkblue font-bold shadow-md w-full flex my-3 p-6 text-base leading-6 justify-between items-center rounded-lg ${props.className}`}
  >
    {props.icon && (
      <div
        className="flex-shrink-0 relative  mr-3"
        style={{ flexBasis: '50px' }}
      >
        <img className="" alt="" src={props.icon} />
      </div>
    )}
    <span className="flex-grow text-left">{props.children}</span>
  </button>
);

export default ChoiceButton;
