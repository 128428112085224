import Head from 'next/head';
import { SITE_CONFIG } from '../../config/config';
import Nav from './Nav';

const MainLayout = (props) => (
  <>
    <Head>
      <title>{props.title || SITE_CONFIG.name}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
      {SITE_CONFIG.favicon && (
        <link rel="icon" type="image/x-icon" href={SITE_CONFIG.favicon} />
      )}
    </Head>
    <Nav title={props.title} onBack={props.onBack} />
    {props.children}
  </>
);

export default MainLayout;
