/* eslint-disable @typescript-eslint/no-explicit-any */
import Router, { useRouter } from 'next/router';
import { useContext, useMemo } from 'react';
import { JsonObject, UserData } from '../base';
import UserContext from '../components/auth/context';
import MainLayout from '../components/structure/MainLayout';
import ChoiceButton from '../components/structure/elements/ChoiceButton';

const claimTypes = {
  auditConduiteECF: {
    label: 'Un audit de la conduite',
    sub: 'Réserver un audit ou se faire rembourser un audit effectué',
    icon: '/images/icons/contravention.svg',
  },
  stageRecuperationECF: {
    label: 'Un stage de récupération de points',
    sub: 'Réserver un stage ou se faire rembourser un stage effectué',
    icon: '/images/icons/stage_recuperation.svg',
  },
  litigeAchatVehicule: {
    label: 'J’ai un litige lié à mon véhicule',
    sub: 'et je souhaite une assistance à la résolution',
    icon: '/images/icons/litige.svg',
  },
  contestationContravention: {
    label: "J'ai reçu une contravention injustifiée",
    sub: 'et je souhaite la contester',
    icon: '/images/icons/contravention.svg',
  },
  convocationTribunal: {
    label: "J'ai reçu une convocation au tribunal",
    sub: "à la suite d'une contravention",
    icon: '/images/icons/convocation.svg',
  },
  rendezVousJuridique: {
    label: 'Je souhaite obtenir un renseignement juridique',
    sub: 'avec un juriste ou un avocat en visio-conférence',
    icon: '/images/icons/visio.svg',
  },
  recouvrementFacture: {
    label: 'Je souhaite recouvrer une facture impayée',
    sub: 'et suivre mes demandes',
    icon: '/images/icons/invoice.svg',
    onClick: () => {
      Router.push('/recouvrement');
    },
  },
  litigeCommercial: {
    label: 'Je souhaite déclarer un litige commercial',
    sub: "J'ai un litige avec un client ou un fournisseur",
    icon: '/images/icons/client.svg',
  },
  documentationJuridique: {
    label: 'Je souhaite générer des documents',
    sub: "avec plein d'informations dedans",
    icon: '/images/icons/contravention.svg',
  },
  stageRecuperation: {
    label: 'Je souhaite me faire rembourser un stage de récupération de points',
    sub: 'en envoyant mes pièces justificatives',
    icon: '/images/icons/stage_recup_points.svg',
  },
  permisInvalide: {
    label: 'Mon permis a été invalidé',
    sub: 'et je souhaite me faire rembourser les frais de renouvellement',
    icon: '/images/icons/permis.svg',
  },
};

const App = () => {
  const router = useRouter();
  const { user } = useContext(UserContext);
  const userServices = Object.keys((user as UserData)?.services || []);

  const claimTypesFiltered = useMemo(
    () =>
      userServices
        .map((service) => {
          if (service.includes('link')) {
            const data = (user as UserData)?.services[service] as JsonObject;
            return {
              label: data.title,
              sub: data.subtitle,
              icon: data.icon,
              onClick: () => {
                window.location.href = data.url as string;
              },
            };
          }
          return {
            ...claimTypes[service],
            path: `/claim/${service}`,
          };
        })
        .filter((x) => !!x.label),
    [userServices]
  );

  const getNavigate = (path) => (e) => {
    e.preventDefault();
    router.push(path);
  };

  // template du bouton de choix
  const getClaimButton = (data, idx) => (
    <ChoiceButton
      key={idx}
      onClick={data.onClick || getNavigate(data.path)}
      icon={data.icon}
      className="my-8"
    >
      {data.label}
      <span className="block text-sm font-normal opacity-75 leading-none">
        {data.sub}
      </span>
    </ChoiceButton>
  );

  return (
    <MainLayout>
      <div className="indexActionsList mx-auto px-5 md:px-0 max-w-lg my-10 mb-12">
        <div className="text-title">Quelle est votre demande ?</div>
        <div className="text-normal">
          Préciser le type de demande sur lequel vous souhaiteriez que nous
          intervenions
        </div>
        {claimTypesFiltered.map(getClaimButton)}
      </div>
      &nbsp; {/* // TODO refaire ça */}
    </MainLayout>
  );
};

App.needAuth = true;

// This gets called on every request
export async function getServerSideProps() {
  return { props: {} };
}

export default App;
